import * as React from "react"
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Zoom,
  Autoplay
} from "swiper"

// Import Swiper styles
import "swiper/swiper.scss"
import "swiper/components/navigation/navigation.scss"
import "swiper/components/pagination/pagination.scss"
import "swiper/components/scrollbar/scrollbar.scss"
import "swiper/components/zoom/zoom.scss"
import SliderStyle from "./Slider.scss"
//components/zoom/zoom.scss

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Zoom, Autoplay])

// // install Swiper modules
// SwiperCore.use([Navigation, Pagination, Scrollbar, A11y])

//================================
// // Initialize new Swiper instance
// const swiper = new Swiper(".swiper-container", {
//   // Setting default settings
//   grabCursor: true,
//   centeredSlides: true,
//   loop: true,
//   // Setting minimum and maximum zoom ration
//   zoom: {
//     maxRatio: 1.2,
//     minRation: 1
//   }
// })

// // Use built in zoom.in() and zoom.out() function to scale images
// // When slide starts to change slideChangeTransitionStart event fires and we use it to scale down the image.
// swiper.on("slideChangeTransitionStart", swiper.zoom.out)
// // And when transition has finished scale it up.
// swiper.on("slideChangeTransitionEnd", swiper.zoom.in)
//================================

const ComponentSlider = ({ screenShots }) => {
  return (
    <Swiper
      className={`${SliderStyle.swiperPaginationBulletActive} 
      ${SliderStyle.swiperPaginationBullet} 
      ${SliderStyle.swiperButtonNext} 
      ${SliderStyle.swiperButtonPrev} 
      ${SliderStyle.swiperScrollbar} 
      ${SliderStyle.swiperScrollbarDrag} 
      `}
      spaceBetween={20}
      slidesPerView={4}
      loop={true}
      zoom={true}
      autoplay={{
        delay: 2500,
        disableOnInteraction: true
      }}
      centeredSlides={false}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true, hide: true }}
      onSwiper={swiper => console.log(swiper)}
      onSlideChange={() => console.log("slide change")}
      // onClick={() => Swiper.zoom.out}
      // onClick={event => {
      //   console.log({ event })
      //   // event.clickedSlide.
      //   return {
      //     zoom: {
      //       maxRatio: 1.1,
      //       minRation: 1
      //     }
      //   }
      // }}
    >
      {/* {sliderData.map(data => { */}
      {screenShots.map(data => {
        return (
          <SwiperSlide
            key={data.id}
            className={`${SliderStyle.swipercontainer}  slide `}
            zoom={true}
          >
            <div className="slide-content">
              <div className="screen-shot swiper-zoom-container maxRation={1.5}">
                <img src={data.url} alt={data.title}></img>
              </div>
              {/* <h5>{data.title}</h5> */}
            </div>
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}

export default ComponentSlider
