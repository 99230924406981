// import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import React, { useState, useEffect } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import "bootstrap/dist/css/bootstrap.min.css"
import { Card, Container, Row } from "react-bootstrap"
import ListOfImageCardViewStyle from "./ListOfImageCardView.scss"

const ListOfImageCardView = ({ titleFilter }) => {
  const [title, setTitle] = useState("")

  useEffect(() => {
    setTitle(titleFilter)
  }, [titleFilter])

  // console.log({ title, titleFilter })
  const data = useStaticQuery(graphql`
    query {
      appInfo: allContentfulAppDetail(
        sort: { fields: publishedDate, order: DESC }
      ) {
        edges {
          node {
            appTitle
            slug
            #======
            appIcon {
              id
              file {
                url
              }
              title
              contentful_id
              fluid {
                src
              }
            }
            # ======
            screenShots {
              id
              title
              file {
                url
                details {
                  size
                  image {
                    width
                    height
                  }
                }
              }

              fluid {
                src
                sizes
              }
            }

            #=========
            apkFile {
              title
              description
              file {
                details {
                  size
                }
              }
            }
            #=========
            excerpt {
              json
            }
            publishedDate(formatString: "MMM Do, YYYY")
            updatedDate(formatString: "MMM Do, YYYY")
            apkFileRelativePath
          }
        }
      }
      # TODO: please also include ios apps extension to be include in the filter
      apkFile: allFile(filter: { extension: { eq: "apk" } }) {
        edges {
          node {
            size
            relativePath
          }
        }
      }
    }
  `)

  return (
    <Container
      style={{ margin: "10px" }}
      className={ListOfImageCardViewStyle.cardContainer}
    >
      <h4 style={{ textAlign: "center", color: "#018FD3" }}>
        Get More Ethio telecom Mobile Apps
      </h4>
      <ol
        className={ListOfImageCardViewStyle.posts}
        style={{ listStyle: "none" }}
      >
        {data.appInfo.edges.map(post => {
          if (post.node.appTitle === title) {
            console.log({ title })
            return null
          }
          return (
            <li key={post.node.slug}>
              <ImageCardView post={post} />
            </li>
          )
        })}
      </ol>
    </Container>
  )
}

const ImageCardView = ({ post }) => {
  return (
    <Card
      className={" mb-3"}
      style={{
        maxWidth: "100%",
        padding: "3px"
      }}
    >
      <div className="row no-gutters">
        <Row>
          <Card.Img
            src={post.node.appIcon.fluid.src}
            alt={post.node.appIcon.title}
            style={{
              maxWidth: "30%",
              margin: "auto",
              padding: "3px"
            }}
          ></Card.Img>
        </Row>
        <Row
          style={{
            // maxWidth: "60%",
            margin: "auto",
            padding: "3px"
          }}
        >
          <Card.Title>
            <Card.Title>
              {/* <Card.Link
                href={`/apps/${post.node.slug}`}
                style={{
                  color: "#008dd2",
                  fontSize: "0.70rem",
                  fontWeight: 600,
                  textAlign: "center",
                  padding: "5px"
                  // color: " #8DC63F"
                }}
              >
                {post.node.appTitle}
              </Card.Link> */}

              <Link
                to={`/apps/${post.node.slug}`}
                style={{
                  color: "#008dd2",
                  fontSize: "0.70rem",
                  fontWeight: 600,
                  textAlign: "center",
                  padding: "5px"
                  // color: " #8DC63F"
                }}
              >
                {" "}
                {post.node.appTitle}
              </Link>
            </Card.Title>
          </Card.Title>
        </Row>
      </div>
    </Card>
  )
}

export default ListOfImageCardView
