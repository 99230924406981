// import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import { Card } from "react-bootstrap"
import { BiDownload } from "react-icons/bi"

import playstoreImg from "../images/google-play-store-logo-svgrepo-com.svg"
// import { CgDetailsMore } from "react-icons/cg"
// import CardStyles from "./AppCardView.module.scss"

const LeftImageCardView = ({ post }) => {
  //   console.log({ post })
  // const options = {
  //   renderNode: {
  //     "embedded-asset-block": node => {
  //       const alt = node.data.target.fields.appTitle["en-US"]
  //       const url = node.data.target.fields.file["en-US"].url
  //       return <img alt={alt} src={url} />
  //     }
  //   }
  // }

  let width =
    post?.contentfulAppDetail?.appIcon?.title === "teledrive App" ? "100%" : "150px!important"
  return (
    <Card className={" mb-3"} style={{ maxWidth: "100%", padding: "3px" }}>
      <div className="row no-gutters">
        <div className="col-md-4" style={{ padding: "3px" }}>
          <Card.Img
            
            src={post?.contentfulAppDetail?.appIcon?.fluid?.src}
            alt={post?.contentfulAppDetail?.appIcon?.title}
            style={{ maxWidth: "60%",width:width,  margin: "auto", padding: "3px" }}
          ></Card.Img>
        </div>
        <div className="col-md-8">
          <Card.Body>
            <Card.Title
              style={{ color: "#008dd2", fontSize: "1.25rem", fontWeight: 600 }}
            >
              {post.contentfulAppDetail.appTitle}
            </Card.Title>

            <Card.Text>
              <p style={{ fontSize: "0.65rem" }}>
                <span>
                  Published:{" "}
                  <strong>{post.contentfulAppDetail.publishedDate}</strong>
                </span>
                <br />
                <span>
                  Updated:{" "}
                  <strong>{post.contentfulAppDetail.updatedDate}</strong>
                </span>
              </p>
            </Card.Text>
            <Card.Link
              href={ 
                post?.contentfulAppDetail?.googlePlayStoreLink !== null
                  ? post?.contentfulAppDetail?.googlePlayStoreLink
                  : post?.contentfulAppDetail?.apkFileRelativePath
              }
            >
              <small className="text-muted">
                Download from{" "}
                {post?.contentfulAppDetail?.googlePlayStoreLink !== null ? (
                  <img
                    alt="download"
                    src={playstoreImg}
                    width={`20px`}
                    height={`20px`}
                  />
                ) : (
                  <BiDownload />
                )}
                
              </small>
            </Card.Link>
            {(post.contentfulAppDetail?.googlePlayStoreLink && post.contentfulAppDetail?.apkFileRelativePath && post.contentfulAppDetail?.apkFileRelativePath.includes("telebirr_customer_app_v4.apk") ) && <><span style={{marginLeft:"4px",marginRight:"4px"}}>or</span>
            <Card.Link
            href={post?.contentfulAppDetail?.apkFileRelativePath
            }
          >
            <small className="text-muted"> 
                <BiDownload />
            </small>
          </Card.Link>
          </>}
            <br />
            <small>
              Size:{" "}
              {post?.contentfulAppDetail?.apkFile?.file &&
                (
                  parseFloat(
                    post?.contentfulAppDetail?.apkFile?.file?.details?.size
                  ) /
                  (1024 * 1024)
                ).toFixed(1)}
              {" MB"}
            </small>
            <br />
          </Card.Body>
        </div>
      </div>
    </Card>
  )
}

export default LeftImageCardView
