import React from "react"
import { graphql, Link } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { GrLinkPrevious } from "react-icons/gr"
import appStyles from "./app.module.scss"
import { Row, Col } from "react-bootstrap"

import Layout from "../components/Layout"
import Head from "../components/Head"
import LeftImageCardView from "../components/LeftImageCardView"
import ListOfImageCardView from "../components/ListOfImageCardView"
import ComponentSlider from "../components/Slider"
import playstoreImg from "../images/google-play-store-logo-svgrepo-com.svg"
import { FaDownload } from "react-icons/fa"

export const query = graphql`
         query($slug: String!) {
           contentfulAppDetail(slug: { eq: $slug }) {
             appTitle
             googlePlayStoreLink
             appIcon {
               id
               file {
                 url
               }
               title
               contentful_id
               #======
               fluid {
                 src
               }
             }
             apkFileRelativePath
             publishedDate(formatString: "MMM Do, YYYY")
             updatedDate(formatString: "MMM Do, YYYY")
             description {
               json
             }
             termsAndConditions {
               json
             }
             #=========
             screenShots {
               id
               title
               file {
                 url
                 details {
                   size
                   image {
                     width
                     height
                   }
                 }
               }

               fluid {
                 src
                 sizes
               }
             }
             #=========
             apkFile {
               title
               description
               file {
                 details {
                   size
                 }
               }
             }
             #=========
           }
         }
       `

const App = ({ data }) => {
  const options = {
    renderNode: {
      "embedded-asset-block": node => {
        const alt = node.data.target.fields.appTitle["en-US"]
        const url = node.data.target.fields.file["en-US"].url
        return <img alt={alt} src={url} height={400} />
      }
    }
  }

  // console.log({ contentfulAppDetail: data.contentfulAppDetail })
  const screenShots = data.contentfulAppDetail.screenShots.map(imageDetails => {
    return {
      id: imageDetails.id,
      title: imageDetails.title,
      url: imageDetails.fluid.src
    }
  })
  return (
    <Layout>
      <div className={appStyles?.container}>
        <Row>
          <Col xs={12} md={8} lg={9}>
            <div>
              <div>
                <LeftImageCardView post={data} />
                <Head title={data?.contentfulAppDetail?.appTitle} />

                <h2 style={{ color: "#008dd2", fontSize: "1.25rem" }}>
                  {data?.contentfulAppDetail?.appTitle + " "} Overview
                </h2>
                {documentToReactComponents(
                  data?.contentfulAppDetail?.description?.json,
                  options
                )}
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "40px",
                    marginBottom: "40px",
                    color: " #8DC63F!important"
                  }}
                >
             <div style={{display:"flex",alignItems:"center",gap:"2px",justifyContent:"center",width:"full"}} className="">
              <span style={{whiteSpace: "nowrap" }}> Download from</span>
                  <a
                    href={
                      // data.contentfulAppDetail.apkFileRelativePath
                      data?.contentfulAppDetail?.googlePlayStoreLink !== null
                        ? data?.contentfulAppDetail?.googlePlayStoreLink
                        : data?.contentfulAppDetail?.apkFileRelativePath
                    }
                    target={
                      data?.contentfulAppDetail?.googlePlayStoreLink !== null
                        ? "__blank"
                        : ""
                    }
                  >
                  
                    {data?.contentfulAppDetail?.googlePlayStoreLink !== null ? (
                      <div style={{display:"flex",flexDirection:"column",gap:"2px",alignItems:"center",justifyContent:"center"}}>
                      <img
                        alt="download"
                        src={playstoreImg}
                        width="20px"
                        height="20px"
                      />
                      <span style={{fontSize:"10px",fontStyle:"normal",fontWeight:"100", whiteSpace: "nowrap" }}>Play Store</span>
                      </div>
                    ) : (
                      <div style={{display:"flex",flexDirection:"column",gap:"4px",alignItems:"center",justifyContent:"center"}}>
                <FaDownload style={{  width:"20px",
                height:"20px"}} />
                
                <span style={{fontSize:"10px",fontStyle:"normal",fontWeight:"100", whiteSpace: "nowrap" }}>Ethio App Store</span>
                </div>
                    )}
                  </a>

                  {(data?.contentfulAppDetail?.apkFileRelativePath && data?.contentfulAppDetail?.apkFileRelativePath.includes("telebirr_customer_app_v5") ) &&  <span>or</span> }
            {(data?.contentfulAppDetail?.apkFileRelativePath && data?.contentfulAppDetail?.apkFileRelativePath.includes("telebirr_customer_app_v5") )&&  <a           
              className={appStyles?.appDownloadLink} 
              href={data?.contentfulAppDetail?.apkFileRelativePath}
              style={{ background: "transparent" }}
              target={data?.contentfulAppDetail?.apkFileRelativePath !== null ? "__blank" : ""}
            >
               
       <div style={{display:"flex",alignItems:"center",gap:"4px"}}> 
       
           <div style={{display:"flex",flexDirection:"column",gap:"2px",alignItems:"center",justifyContent:"center"}}>
                <FaDownload style={{  width:"20px",
                height:"20px"}} />
                
                <span style={{fontSize:"10px",fontStyle:"normal",fontWeight:"100", whiteSpace: "nowrap" }}>Ethio App Store</span>
                </div>
                
                
                
                  </div>          
              
            </a>}


                  </div>
           
                  <br />
                  <span>
                    Size:{" "}
                    {data?.contentfulAppDetail?.apkFile?.file &&
                      (
                        parseFloat(
                          data?.contentfulAppDetail?.apkFile?.file?.details
                            ?.size
                        ) /
                        (1024 * 1024)
                      ).toFixed(1)}
                    {" MB"}
                  </span>
                  <br />
                </div>
                <br />
                <ComponentSlider screenShots={screenShots} />
                <br />

                <div
                  style={{
                    textAlign: "center",
                    marginTop: "20px",
                    marginBottom: "40px"
                  }}
                >
                  {/* <Link to="/" onClick="window.location.href=this"> */}
                  <Link
                    to="/apps"
                    // onClick="window.location.href=this"
                  >
                    <GrLinkPrevious style={{ fill: "green" }} /> Back to All
                    Apps
                  </Link>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={8} md={4} lg={3} className={appStyles?.colContent}>
            <ListOfImageCardView
              titleFilter={data?.contentfulAppDetail?.appTitle}
            />
          </Col>
        </Row>
        <Row>
          {data?.contentfulAppDetail?.termsAndConditions && (
            <div style={{margin:"4px", fontSize:"0.75em"}}>
              <h2 style={{ color: "#008dd2", fontSize: "1.25rem" }}>
                {data?.contentfulAppDetail?.appTitle + " "} Terms and Conditions
              </h2>
              {documentToReactComponents(
                data?.contentfulAppDetail?.termsAndConditions?.json,
                options
              )}
            </div>
          )}
        </Row>
      </div>
    </Layout>
  )
}

export default App
